<template>
  <b-container
    fluid="xl"
  >
    <div class="py-4 d-flex align-items-center">
      <div class="h3 mr-4 ">
        Направление на консультацию через импорт
      </div>
    </div>
    <div class="d-flex mb-2">
      <v-select
        v-model="selectedQueue"
        :options="queueList"
        :reduce="queue => queue.id"
        label="name"
        class="w-100 rounded bg-white"
        placeholder="Выберите очередь"
      />
    </div>

    <div class="d-flex mb-2">
      <v-select
        v-model="selectedCompanyId"
        :reduce="company => company.id"
        :options="insuranceCompanies"
        placeholder="Выберите компанию"
        label="title"
        class="w-50 rounded bg-white "
        @input="changeCompany"
      />
      <v-select
        v-model="selectedProgramId"
        :reduce="program => program.id"
        :options="insurancePrograms"
        placeholder="Выберите программу"
        label="name"
        class="w-50 ml-4 rounded bg-white "
        @input="changeProgram"
      />
      <!-- <v-select
        v-model="selectedSubprogramId"
        :reduce="subprogram => subprogram.id"
        :options="insuranceSubprograms"
        placeholder="Выберите подпрограмму"
        label="name"
        class="crm-form-field"
      /> -->
    </div>

    <div class="rounded bg-white mb-2">
      <v-select
        v-model="selectedSpecialization"
        :options="specializations"
        placeholder="Выберите специализацию"
        fluid
        label="name"
      />
    </div>

    <div class="d-flex mb-2">
      <b-button
        size="sm"
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="ml-auto"
        :disabled="isLoading"
        @click="onClickOpenFileModal"
      >
        Загрузить файл
      </b-button>
    </div>

    <preloader v-if="isLoading" />

    <div
      v-if=" !isLoading"
      class="policies-table bg-white rounded border"
    >
      <div class="policies-table-wrapper">
        <div
          ref="table-header"
          class="policies-table-header"
        >
          <div
            v-for="(title, i) in selectedColumnNames"
            :key="i"
            class="policies-table-header-cell"
          >
            <v-select
              :value="availableColumnNames.filter((name) => name.id === selectedColumnNames[i])[0]"
              :append-to-body="true"
              :reduce="program => program.id"
              :searchable="false"
              :clearable="true"
              :options="filteredAvailableColumnNames"
              :calculate-position="setMaxHeight"
              class="select-col-name"
              label="name"
              placeholder="Игнорировать"
              @input="selectColumnName($event, i)"
            />
          </div>
        </div>
        <div class="policies-table-body">
          <vuescroll
            :ops="ops"
            class="vuescroll-custom"
            @handle-scroll="handleScroll"
          >
            <div class="policies-table-inner">
              <div
                v-for="(row, rowIndex) in tableRows"
                :key="rowIndex"
                class="policies-table-row"
              >
                <input
                  v-for="(cell, cellIndex) in row"
                  :key="cellIndex"
                  class="policies-table-cell"
                  type="text"
                  disabled
                  :value="cell"
                >
                <!-- readonly="readonly" -->
              </div>
            </div>
          </vuescroll>
        </div>

        <div class="d-flex">
          <b-button
            size="sm"
            :variant=" totalRowsInFile ? 'primary' : 'secondary'"
            :type="$const.PRIMARY_BUTTON"
            class="ml-auto mt-3"
            @click="onClickOpenImportModal"
          >
            Загрузить
          </b-button>
        </div>
      </div>
      <div
        v-if="tableRows.length < totalRowsInFile"
        class="show-more-btn"
        @click="showAllRows"
      >
        Показать полностью
      </div>
    </div>
  </b-container>
</template>

<script>
import vuescroll from 'vuescroll';

import { mixinRoles } from '@/mixins';
import { api } from '@/helpers/api';
import { showCustomMessage } from '@/helpers/messages';

import Preloader from '@/components/Preloader';

export default {
  name: 'ReferralDutyConsultationImport',
  page: {
    title: 'CRM Doctis - Направление на консультацию через импорт',
  },
  components: {
    vuescroll,
    Preloader,
  },
  mixins: [mixinRoles],
  data() {
    return {
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      selectedCompanyId: null,
      selectedProgramId: null,
      selectedSubprogramId: null,
      specializations: [],
      selectedSpecialization: null,
      isLoading: false,
      isLoadingFromFile: false,
      fileGUID: undefined,
      fileName: undefined,
      totalRowsInFile: 0,
      availableColumnNames: [
        { id: 0, name: 'Номер полиса' },
        { id: 2, name: 'Фамилия' },
        { id: 1, name: 'Имя' },
        { id: 3, name: 'Отчество' },
        { id: 4, name: 'Дата рождения' },
        { id: 7, name: 'Номер телефона' },
        { id: 14, name: 'Новый объем услуг' },
        { id: 33, name: 'Текст обращения' },
        { id: 8, name: 'Страхователь' },
        { id: 16, name: 'Паспорт Серия' },
        { id: 17, name: 'Паспорт Номер' },
        { id: 18, name: 'Паспорт Дата выдачи' },
        { id: 19, name: 'Паспорт Кем выдан' },
        { id: 20, name: 'Паспорт Код подразделения' },
        { id: 13, name: 'СНИЛС' },
        { id: 5, name: 'Дата начала полиса' },
        { id: 6, name: 'Дата окончания полиса' },
      ],
      filteredAvailableColumnNames: [],
      miniDisplayedRows: 10,
      selectedColumnNames: [],
      tableRows: [],
      selectedQueue: null,
      queueList: [],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
        //   background: '#D5D9E5',
          // gutterOfSide: '-5px',
          // gutterOfEnds: '100px',
          opacity: 0,
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#E0E0E0',
          minSize: 0.15,
          size: '5px',
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));
    this.setInitialData();
    this.specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
    this.setInsuranceEntities();
    this.queueList = await this.$store.dispatch(this.$types.DUTY_QUEUE_LIST_FETCH);
    this.isLoading = false;
  },
  methods: {
    setInitialData() {
      try {
        this.filteredAvailableColumnNames = this.availableColumnNames;
        this.selectedColumnNames = this.availableColumnNames.map(() => null);
        this.tableRows = [...Array(this.miniDisplayedRows)].map(() => this.availableColumnNames.map(() => ''));
      } catch (error) {
        console.error(error);
      }
    },
    getFormatedSelectedRowsForBackend() {
      return this.availableColumnNames.map((colType) => {
        let colIndexForThisColName = this.selectedColumnNames.indexOf(colType.id);
        if (colIndexForThisColName === -1) {
          colIndexForThisColName = null;
        }
        return { propertyId: colType.id, columnNumber: colIndexForThisColName };
      });
    },
    onClickOpenFileModal() {
      if (!this.selectedQueue) {
        showCustomMessage('warning', 'Ошибка', 'Необходимо выбрать очередь');
        return;
      }

      if (!this.selectedCompanyId) {
        showCustomMessage('warning', 'Ошибка', 'Необходимо выбрать компанию');
        return;
      }

      if (!this.selectedProgramId) {
        showCustomMessage('warning', 'Ошибка', 'Необходимо выбрать программу');
        return;
      }

      if (!this.selectedSpecialization) {
        showCustomMessage('warning', 'Ошибка', 'Необходимо выбрать специализацию');
        return;
      }

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FileModalModernNew',
        props: {
          saveFileCallback: this.saveFileCallback,
          multiple: false,
        },
      });
    },
    onClickOpenImportModal() {
      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoading;
      if (!hasFile) {
        showCustomMessage('warning', 'Ошибка', 'Необходимо загрузить файл');
        return;
      }

      const params = {
        fileId: this.fileGUID,
        fileName: this.fileName,
        AllRowsCount: this.totalRowsInFile,
        TargetSpecializationId: this.selectedSpecialization.id,
        // SubprogramId: this.selectedSubprogramId,
        companyId: this.selectedCompanyId || undefined,
        programId: this.selectedProgramId || undefined,
        queue: this.selectedQueue || undefined,
        Columns: this.getFormatedSelectedRowsForBackend(),
      };

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ReferralDutyConsultationImportModal',
        props: {
          params,
        },
      });
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },

    generateEmptyRows(rowsCount, colsCount) {
      const rows = new Array(rowsCount).fill([]);

      for (let i = 0; i < rowsCount; i += 1) {
        rows[i] = new Array(colsCount).fill('');
      }

      return rows;
    },

    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoading = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows?guid=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoading = false;
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid;
      this.fileName = newFile.name;
      await this.getTableRows(10, true);
    },

    handleScroll(vertical, horizontal) { // vertical, horizontal, nativeEvent
      // console.log(vertical, horizontal);

      // Table header horizontal scroll
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
      // header.style.left = `-${value}px`;

      // Table row delete button list vertical scroll
      // const deleteList = this.$refs['row-delete-list'];
      // const deleteListScrollValue = Math.round(vertical.process * (deleteList.scrollHeight - deleteList.clientHeight));
      // deleteList.scrollTop = deleteListScrollValue;

      // console.log(deleteListScrollValue);
      // console.dir(deleteList);
    },
    selectColumnName(e, i) {
      this.selectedColumnNames[i] = e;
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.id));
    },
    setMaxHeight(dropdownList, component, { width, top, left }) {
      /* eslint-disable no-param-reassign */
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      // dropdownList.style.maxHeight = 'calc(100vh - 100px)';
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    setInsuranceEntities() {
      if (!this.selectedCompanyId) return;

      const companyObject = this.insuranceData
        .filter(({ company: { id } }) => id === this.selectedCompanyId)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;

      if (!this.selectedProgramId) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.selectedProgramId)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    changeCompany() {
      this.selectedProgramId = null;
      this.selectedSubprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.selectedSubprogramId = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
  },
};
</script>

<style lang="scss" scoped>
.policies-table {
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;

  * {
    box-sizing: border-box;
  }
}

.policies-table-wrapper {
  width: 100%;
  // overflow: hidden;
}

.policies-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.policies-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.policies-table-body {
  height: 350px;
  position: relative;
}

::v-deep.policies-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
  //& + .policies-table-row {
  //  border-bottom: 1px solid #E0E0E0;
  //}
}

//.policies-table-row-delete-list {
//  position: absolute;
//  left: -50px;
//  top: 0;
//  bottom: 0;
//  z-index: 1;
//  overflow: hidden;
//}

::v-deep.policies-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .policies-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

::v-deep.specialization-filter {
  .crm-checkbox-list-wrapper {
    height: 350px !important;
  }
}

</style>
